<script setup>
import { ref, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import Loader from 'sja.vue.libraries.charts.Loader.vue';

let series = ref([]);
let categories = ref([]);
let loaded = ref(false);
let errorMsg = ref();
let startWeek = ref();
let endWeek = ref();
let weeksBetween = ref([]);

const dsSJACreated = getOrCreateDataObject({
    id: 'dsSJACreated',
    viewName: 'aviw_SJA_SJA',
    maxRecords: -1,
    whereClause: `Workflow_Deleted IS NULL`,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields: [
        { name: "Created" },
        { name: "WeekCreated" },
        { name: "WeekAndYearCreated" },
        { name: "OrgUnitIdPath" },
    ]
});

dsSJACreated.enableContextFilter();

dsSJACreated.on('DataLoaded', async (data, options) => {
    categories.value = [
        ...new Set(dsSJACreated.data.map((element) => element.WeekAndYearCreated)),
    ];

    selectStartAndEndWeek(categories.value)

    weeksBetween.value = generateWeeksBetween(startWeek.value, endWeek.value);

    let seriesData = []

    weeksBetween.value.forEach((value, index) => {
        seriesData.push(dsSJACreated.data.filter(row => row.WeekAndYearCreated === value).length)
    });

    series.value.push({ name: $t('SJA'), data: seriesData });

    await createChart();

    loaded.value = true;

});

onMounted(async () => {
    await dsSJACreated.load();
});

function generateWeeksBetween(startWeek, endWeek) {
    const [startYear, startWeekNumber] = startWeek.split('-').map(Number);
    const [endYear, endWeekNumber] = endWeek.split('-').map(Number);

    const generatedWeeks = [];

    for (let year = startYear; year <= endYear; year++) {
        const currentStartWeek = (year === startYear) ? startWeekNumber : 1;
        const currentEndWeek = (year === endYear) ? endWeekNumber : 52;

        for (let week = currentStartWeek; week <= currentEndWeek; week++) {
            generatedWeeks.push(`${year}-${week}`);
        }
    }

    return generatedWeeks;
}

function selectStartAndEndWeek(weekArray) {
    if (weekArray.length === 0) {
        return null;
    }

    const sortedWeeks = weekArray.sort();

    startWeek.value = sortedWeeks[0];

    endWeek.value = sortedWeeks[sortedWeeks.length - 1];
}

function createChart() {
    const chartOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: $t(`SJA's created per week`)
        },
        xAxis: {
            categories: weeksBetween.value
        },
        yAxis: {
            title: {
                text: $t('Total')
            }
        },
        plotOptions: {
            column: {
                colorByPoint: false,
            },
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                drilldown: true
            }
        },
        series: series.value
    };

    Highcharts.chart('createdChartContainer', chartOptions);
}
</script>

<template>
    <div id="createdChartContainer" class="p-2" style="width: 100%;">
        <Loader :loaded="loaded" :errorMsg="errorMsg"></Loader>
    </div>
</template>
